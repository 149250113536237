<template>
  <div class="orderView">
    <!-- :class="{
      orderView4: typeFour,
    }" -->
    <!-- 确认订单 -->
    <div class="confirm_order">
      <div class="order_text">确认订单</div>
      <div class="order_centent">
        <div class="centent_left">
          <span class="order_pay">订单提交成功，请尽快付款！</span>
          <span>订单编号：{{ id }}</span>
        </div>
        <div class="centent_right">
          <div>请您在</div>
          <el-statistic
            v-if="typeOne && detail.countDownTime"
            class="right_time right_time1"
            :value="new Date(detail.countDownTime)"
            time-indices
            format="HH:mm:ss"
            @finish="downFinish"
          />
          <el-statistic
            v-if="typeTwo && detail.countDownTime"
            class="right_time right_time2"
            :value="new Date(detail.countDownTime)"
            time-indices
            format="HH:mm:ss"
            @finish="downFinish"
          />
          <el-statistic
            v-if="typeFour && detail.countDownTime"
            class="right_time right_time3"
            :value="new Date(detail.countDownTime)"
            time-indices
            format="HH:mm:ss"
            @finish="downFinish"
          />
          <el-statistic
            v-if="typeThree && detail.countDownTime"
            class="right_time right_time4"
            :value="new Date(detail.countDownTime)"
            time-indices
            format="HH:mm:ss"
            @finish="downFinish"
          />
          <div>分钟内完成支付，否则订单会被自动取消！</div>
        </div>
      </div>
    </div>
    <!-- 收货地址 -->
    <div class="shipping_address">
      <div class="address_text">收货信息</div>
      <div class="address_centent">
        <div class="centent" @click="editAddress">
          <div class="centent_left">
            <div class="left_img">
              <el-image
                :src="require('@/assets/img/textmall/adds_z.png')"
                v-if="typeOne"
              ></el-image>
              <el-image
                :src="require('@/assets/img/textmall/adds.png')"
                v-else-if="typeTwo"
              ></el-image>
              <el-image
                :src="require('@/assets/img/textmall/adds_g.png')"
                v-else-if="typeThree"
              ></el-image>
              <el-image
                :src="require('@/assets/img/textmall/adds_big.png')"
                v-else-if="typeFour"
              ></el-image>
            </div>
            <div class="left_text" v-if="!addsDetails.reveiveName">
              <div>添加收货地址</div>
            </div>
            <div class="left_text" v-else>
              <p class="consignee">
                收货人：{{ addsDetails.reveiveName }}
                <span class="consignee_phone">{{ addsDetails.mobile }}</span>
              </p>
              <p class="consignee_address">
                收货地址：{{ addsDetails.cityName + addsDetails.address }}
              </p>
            </div>
          </div>
          <div class="cenntent_right">
            <el-image
              :src="require('../../assets/img/textmall/right.png')"
            ></el-image>
          </div>
        </div>
        <div class="centent_bg">
          <el-image
            :src="require('../../assets/img/textmall/line.png')"
          ></el-image>
        </div>
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="commodity">
      <div class="commodity_text">商品信息</div>
      <div class="commodity_centent">
        <div
          class="item"
          v-for="(item, index) in orderParticularsList"
          :key="index"
        >
          <div class="centent_left">
            <el-image class="img" :src="item.commodityImg"></el-image>
          </div>
          <div class="centent_right">
            <div class="right_name">
              <p class="name">{{ item.commodityName }}</p>
              <p
                class="weight"
                :class="{
                  tip1: typeOne,
                  tip2: typeTwo,
                  tip3: typeThree,
                  tip4: typeFour,
                }"
              >
                {{ item.weight }}kg
              </p>
            </div>
            <div class="right_price">
              <p
                class="price"
                v-if="item.commodityPrice || item.commodityPrice === 0"
              >
                ￥{{ (item.commodityPrice / item.commodityNumber).toFixed(2) }}
              </p>
              <p class="num">*{{ item.commodityNumber }}</p>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <!-- 配套服务 -->
    <div class="supporting">
      <div class="supporting_text">配套服务</div>
      <div class="supporting_centent">
        <p>配套服务</p>
        <div class="freight">
          <p>运费：</p>
          <p class="freight_pri" v-if="detail.freight || detail.freight === 0">
            ￥{{ detail.freight.toFixed(2) }}
          </p>
        </div>
      </div>
    </div>
    <!-- 支付方式 -->
    <div class="pay">
      <div class="pay_text">支付方式</div>
      <div class="pay_centent">
        <div
          class="centent_left"
          :class="{
            left1: typeOne,
            left2: typeTwo,
            left3: typeThree,
            left4: typeFour,
          }"
        >
          <el-radio v-model="form.type" :label="1" border class="left_radio">
            <el-image
              class="left_img"
              :src="require('@/assets/img/kecheng/zhifuBao.png')"
            ></el-image>
            <span class="left_payment">支付宝支付</span>
          </el-radio>
          <el-radio v-model="form.type" :label="2" border class="left_radio">
            <el-image
              class="left_img"
              :src="require('@/assets/img/kecheng/weixin.png')"
            ></el-image>
            <span class="left_payment">微信支付</span>
          </el-radio>
        </div>
      </div>
    </div>
    <div class="payment">
      <div class="pay_money">
        <div>需付金额：</div>
        <!-- <div class="money">{{ detail.paidPrice.toFixed(2) }}</div> -->
        <div v-if="form.couponType == 1 || form.couponType == 2" class="money">
          {{
            detail.paidPrice - form.discountPrice > 0
              ? detail.paidPrice - form.discountPrice
              : 0
          }}
        </div>
        <span v-else-if="form.couponType == 3" class="money">
          {{ (detail.paidPrice * form.fullDiscountRate).toFixed(2) }}
        </span>
        <span class="money" v-if="detail.paidPrice || detail.paidPrice === 0">{{
          detail.paidPrice.toFixed(2)
        }}</span>
      </div>
      <div class="payment_btn" @click="lijizhifu" v-if="btnDisabled">
        <el-button>立即支付</el-button>
      </div>
    </div>
    <wx-payment
      v-if="ifwXpay"
      :url="payDetail.code_url"
      :out-trade-no="payDetail.out_trade_no"
      :price="payDetail.total_fee ?? '0'"
      @invalid="wxPay()"
      @close="ifwXpay === false"
      @closeClick="closeCom"
    />

    <!-- <el-dialog
      :visible.sync="pay"
      width="368px"
      append-to-body
      @close="ifwXpay === false"
    >
      <div class="pay-box">
        <i class="iconfont" :class="ifSucces ? 'icon-a-zu16' : 'icon-a-zu17'" />
        <div class="tishi-title">{{ ifSucces ? "支付成功" : "支付失败" }}</div>
        <div class="tishi-tips">
          {{
            ifSucces ? "恭喜您，订单支付成功！" : "抱歉，支付失败，请重新支付。"
          }}
        </div>
        <el-button type="danger" round @click="closeCom">我知道了</el-button>
      </div>
    </el-dialog> -->
    <el-dialog
      class="addressDialog"
      center
      title="新增收货地址"
      :visible.sync="addressDialog"
      :close-on-click-modal="false"
      @close="cancelClick"
    >
      <address-dialog
        v-if="addressDialog"
        :addressForm="addressForm"
        @save="save"
        @cancelClick="cancelClick"
      />
    </el-dialog>
    <!-- 新增、编辑、删除弹窗 -->
    <el-dialog
      class="editaddressDialog"
      center
      title="新增收货地址"
      :visible.sync="editaddressDialog"
    >
      <div class="address">
        <div class="addressAdd">
          <el-button
            class="addBtn"
            :class="{
              addBtn1: typeOne,
              addBtn2: typeTwo,
              addBtn3: typeThree,
              addBtn4: typeFour,
            }"
            @click="addAddress"
            >+ 新增地址</el-button
          >
        </div>
        <div
          class="addressBox"
          v-for="(item, index) in UserAddress"
          :key="index"
        >
          <!-- @click="chooseAdds(item)" -->
          <div class="default_img" v-if="item.isDefault == 1 && typeOne">
            <el-image
              :src="require('@/assets/img/textmall/default_z.png')"
            ></el-image>
          </div>
          <div class="default_img" v-if="item.isDefault == 1 && typeTwo">
            <el-image
              :src="require('@/assets/img/textmall/default.png')"
            ></el-image>
          </div>
          <div class="default_img" v-if="item.isDefault == 1 && typeThree">
            <el-image
              :src="require('@/assets/img/textmall/default_g.png')"
            ></el-image>
          </div>
          <div class="default_img" v-if="item.isDefault == 1 && typeFour">
            <el-image
              :src="require('@/assets/img/textmall/default_big.png')"
            ></el-image>
          </div>
          <div class="address_centent">
            <div class="address_name">
              <p>
                收货人：<span class="name">{{ item.reveiveName }}</span>
              </p>
              <p>
                联系电话：<span class="name">{{ item.mobile }}</span>
              </p>
            </div>
            <div class="centent_add">
              <p class="add_text">
                收货地址：<span class="add">{{
                  item.cityName + item.address
                }}</span>
              </p>
              <div class="centent_choose">
                <div class="default">
                  <el-image
                    class="add_img"
                    :src="require('@/assets/img/textmall/moren_z.png')"
                    v-if="item.isDefault == 1 && typeOne"
                    @click.stop="setDefaultStuShopAddress(item)"
                  ></el-image>
                  <el-image
                    class="add_img"
                    :src="require('@/assets/img/textmall/moren.png')"
                    v-else-if="item.isDefault == 1 && typeTwo"
                    @click.stop="setDefaultStuShopAddress(item)"
                  ></el-image>
                  <el-image
                    class="add_img"
                    :src="require('@/assets/img/textmall/moren_g.png')"
                    v-else-if="item.isDefault == 1 && typeThree"
                    @click.stop="setDefaultStuShopAddress(item)"
                  ></el-image>
                  <el-image
                    class="add_img"
                    :src="require('@/assets/img/textmall/check_big.png')"
                    v-else-if="item.isDefault == 1 && typeFour"
                    @click.stop="setDefaultStuShopAddress(item)"
                  ></el-image>
                  <div
                    class="circle"
                    v-else
                    @click.stop="setDefaultStuShopAddress(item)"
                  ></div>
                  <p
                    class="deit_text"
                    :class="{
                      deit_text1: typeOne && item.isDefault == 1,
                      deit_text2: typeTwo && item.isDefault == 1,
                      deit_text3: typeThree && item.isDefault == 1,
                      deit_text4: typeFour && item.isDefault == 1,
                    }"
                    @click.stop="setDefaultStuShopAddress(item)"
                  >
                    设为默认地址
                  </p>
                </div>
                <div class="default">
                  <el-image
                    class="deit_img"
                    :src="require('@/assets/img/textmall/edit_z.png')"
                    v-if="typeOne"
                    @click.stop="handleEdit(item)"
                  ></el-image>
                  <el-image
                    class="deit_img"
                    :src="require('@/assets/img/textmall/edit.png')"
                    v-if="typeTwo"
                    @click.stop="handleEdit(item)"
                  ></el-image>
                  <el-image
                    class="deit_img"
                    :src="require('@/assets/img/textmall/edit_g.png')"
                    v-if="typeThree"
                    @click.stop="handleEdit(item)"
                  ></el-image>
                  <el-image
                    class="deit_img"
                    :src="require('@/assets/img/textmall/edit_big.png')"
                    v-if="typeFour"
                    @click.stop="handleEdit(item)"
                  ></el-image>
                  <p
                    class="deit_text"
                    :class="{
                      deit_text1: typeOne,
                      deit_text2: typeTwo,
                      deit_text3: typeThree,
                      deit_text4: typeFour,
                    }"
                    @click.stop="handleEdit(item)"
                  >
                    编辑
                  </p>
                </div>
                <div class="default">
                  <el-image
                    class="del_img"
                    :src="require('../../assets/img/textmall/delete.png')"
                    @click.stop="handleDelete(item)"
                  ></el-image>
                  <p class="del_text" @click.stop="handleDelete(item)">删除</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getUidOrder } from "@/api/user";
import { CouponsClient } from "@/api/mine/couponsClient";
import wxPayment from "./wxPayment.vue";
import { getInfo, getDomain } from "@/api/cookies";
import addressDialog from "@/views/seventhPage/mine/myPage/addressDialog.vue";
import {
  selectTextBookOrder,
  payZfbTextBookOrder,
  payWxTextBookOrder,
  getUserAddress,
  setDefaultStuShopAddress,
  delStuShopAddress,
  addStuShopAddress,
  updateStuShopAddress,
  updateStudentOrderAddress,
  cancelTextBookOrder,
} from "@/api/mykecheng";
const couponsClient = new CouponsClient();
var dayjs = require("dayjs");
export default {
  components: { wxPayment, addressDialog },
  data() {
    return {
      copyright: {},
      /* { name: "同行转型", key: 1 },   { name: "招商加盟", key: 2 },
    { name: "高校合伙人", key: 3 } */
      form: {
        type: 1, // 支付类型
        discountPrice: 0, // 优惠金额
        isInvoice: "1", // 是否需要发拼
        couponId: null, // 选择优惠券
      },
      checkboxs: "",
      detail: {}, // 订单信息
      userInfo: "",
      UserAddress: [], // 收货地址列表
      id: "",
      ifwXpay: false, // 微信支付二维码
      addressDialog: false,
      editaddressDialog: false,
      payDetail: {}, // 微信支付详情
      addsDetails: {}, // 收获地址详情
      couponList: [],
      stuOrderAddress: [],
      orderParticularsList: [],
      /* 支付弹窗 */
      pay: false,
      ifSucces: false,
      /* 协议 */
      agreement: null,
      countDownTime: null,
      // 编辑地址
      addressForm: {},
      // class判断
      typeOne: false,
      typeTwo: false,
      typeThree: false,
      typeFour: false,
      add: "",
      btnDisabled: true,
    };
  },

  watch: {
    countDownTime: {
      handler(newVal, oldVal) {
        if (new Date(newVal).getTime() <= new Date().getTime()) {
          this.cancelOrder(this.detail.id);
        }
      },
      deep: true,
    },
  },
  async created() {
    this.id = this.$route.query.id;
    this.copyright = getDomain();
    this.userInfo = getInfo();
    this.selectTextBookOrder();
    this.getUserAddress();
    // 动态class判定
    /**
     * entrantsType=2   =>  招商加盟
     * entrantsType=3   =>  聚学U
     * entrantsType=5   =>  高校共建
     * entrantsType=1   =>  大客户
     */
    const res = getDomain();
    if (res.entrantsType === 2) {
      this.typeOne = true;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 3) {
      this.typeOne = false;
      this.typeTwo = true;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 5) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = true;
      this.typeFour = false;
    } else if (res.entrantsType === 1) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = true;
    }
  },
  methods: {
    downFinish() {
      this.btnDisabled = false;
    },
    async cancelOrder(val) {
      const res = await cancelTextBookOrder(val);
      if (res.code === 0) {
        this.$message.success("取消订单成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    // 编辑
    handleEdit(val) {
      this.add = val.address;
      this.addressForm = val;
      this.addressDialog = true;
    },
    async handleDelete(val) {
      const res = await delStuShopAddress(val.id);
      if (res.code === 0) {
        this.getUserAddress();
      }
    },
    // 新增订单接口
    async loadgetUidOrder() {
      await getUidOrder(this.$route.params.orderNumber).then((res) => {
        this.detail = res.msg;
      });
    },
    async save(val) {
      const params = Object.assign(val, { orderId: this.detail.id });
      if (val.id) {
        const res = await updateStudentOrderAddress(params);
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.addressDialog = false;
        }
        this.getUserAddress();
      } else {
        const res = await addStuShopAddress(params);
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.addressDialog = false;
        }
        this.getUserAddress();
      }
    },
    async chooseAdds(val) {
      const params = Object.assign(val, { orderId: this.detail.id });
      const res = await updateStudentOrderAddress(params);
      if (res.code == 0) {
        this.addsDetails = val;
        this.$message.success(res.msg);
        this.addressDialog = false;
        this.editaddressDialog = false;
      }
      this.getUserAddress();
    },
    async selectTextBookOrder(id) {
      const res = await selectTextBookOrder(this.id);
      if (res.code == 0) {
        this.detail = res.data;
        this.orderParticularsList = res.data.orderParticularsList;
        this.countDownTime = res.data.countDownTime;
      }
    },
    // 查看可使用的优惠券
    useDiscountCouponCode() {
      if (!this.userInfo || !this.detail) {
        return;
      }
      const type = this.detail.orderParticularsList.map((item) => {
        return item.commodityType;
      });
      const ids = this.detail.orderParticularsList.map((item) => {
        return item.commodityId;
      });
      couponsClient
        .useDiscountCouponCode(
          this.detail.orderPrice,
          type.join(","),
          this.userInfo.id,
          ids.join(",")
        )
        .then((res) => {
          if (res.code == 0) {
            this.couponList = res.data;
          }
        });
    },
    // 选择优惠券
    use(item) {
      if (this.form.couponId == item.couponCodeId) {
        this.form.couponType = null;
        this.form.fullDiscountRate = null;
        this.form.discountPrice = 0;
        this.form.couponId = null;
      } else {
        this.form.couponType = item.couponType;
        this.form.fullDiscountRate = item.fullDiscountRate / 100;
        this.form.discountPrice = item.couponValue;
        this.form.couponId = item.couponCodeId;
      }
    },
    /* 查询是否有协议 */
    // getPcAgreement() {
    //   getPcAgreement(this.detail.commodityId).then((res) => {
    //     if (res.code == 0) {
    //     }
    //   })
    // },
    // 支付方式选择
    zhifu(index) {
      this.form.type = index;
    },
    // 立即支付
    async lijizhifu() {
      // if (this.res && this.checkboxs !== true) {
      //   this.$message.error('请先阅读并同意协议内容！')
      // } else if (this.form.isInvoice !== '0' && this.form.isInvoice !== '1') {
      //   this.$message.error('请选择是否需要发票！')
      // }
      if (!this.addsDetails.reveiveName) {
        this.$message.error("请先选择收货地址");
      } else {
        if (!this.form.type) {
          this.$message.error("请选择支付方式！");
        } else {
          if (this.form.type == 1) {
            // 支付宝支付接口
            const res = await payZfbTextBookOrder(this.id);
            if (res.code == 0) {
              if(res.data.code == 0){
                if (this.copyright.entrantsType == 2) {
                  this.$router.replace({ path: "/my/mineOrder" });
                  // windows.open("/my/mineOrder")
                } else if (this.copyright.entrantsType == 1) {
                  this.$router.replace({ path: "/mysixth/mineOrder" });
                } else if (this.copyright.entrantsType == 3) {
                  this.$router.replace({ path: "/seventhPage/my/mineOrder" });
                }
              }
              else if(res.data.code === 200){
                this.$router.replace({ path: '/payment', query: { data: res.data.data }})
              }
            } else if (res.msg == 205) {
              this.pay = true;
              this.ifSucces = true;
            } else if (res.msg == 500) {
              this.pay = true;
              this.ifSucces = false;
            }else if (res.code == 500) {
              this.$message.error(res.msg);
              // this.pay = true;
              // this.ifSucces = false;
            }
          } else {
            // 微信支付接口
            this.wxPay();
          }
        }
      }
    },
    /* 我知道了 */
    /* 关闭组件 */
    closeCom(val) {
      if (val) {
        if (this.copyright.entrantsType == 2) {
          this.$router.replace({ path: "/my/mineOrder" });
        } else if (this.copyright.entrantsType == 1) {
          this.$router.replace({ path: "/mysixth/mineOrder" });
        } else if (this.copyright.entrantsType == 3) {
          this.$router.replace({ path: "/seventhPage/my/mineOrder" });
        }
      }
    },
    async wxPay() {
      const res = await payWxTextBookOrder(this.id);
      if (res.code == 0) {
        if (res.data.code === 0) {
          this.$message.success("支付成功");
          if (this.copyright.entrantsType == 2) {
            this.$router.replace({ path: "/my/mineOrder" });
            // windows.open("/my/mineOrder")
          } else if (this.copyright.entrantsType == 1) {
            this.$router.replace({ path: "/mysixth/mineOrder" });
          } else if (this.copyright.entrantsType == 3) {
            this.$router.replace({ path: "/seventhPage/my/mineOrder" });
          }
        } else if (res.data.code === 200) {
          this.payDetail = res.data;
          this.ifwXpay = true;
          this.$forceUpdate();
        }
      } else if (res.msg == 205) {
        this.pay = true;
        this.ifSucces = true;
      } else if (res.code == 500) {
        this.$message.error(res.msg);
        // this.pay = true;
        // this.ifSucces = false;
      }
    },
    goNext() {
      if (this.copyright.entrantsType == 2) {
        this.$router.push({ path: "/my/mineOrder" });
      } else if (this.copyright.entrantsType == 1) {
        this.$router.push({ path: "/seventhPage/my/mineOrder" });
      } else if (this.copyright.entrantsType == 3) {
        this.$router.push({ path: "/seventhPage/my/mineOrder" });
      }
    },
    async getUserAddress() {
      const res = await getUserAddress();
      if (res.code == 0) {
        this.UserAddress = res.msg;
        this.UserAddress.forEach((item) => {
          if (item.isDefault === 1) {
            this.addsDetails = item;
          } else {
            this.addsDetails = this.UserAddress[0];
          }
        });
      } else if (res.code === 500) {
        this.UserAddress = [];
        this.addsDetails = {};
      }
    },
    // 设置默认地址
    async setDefaultStuShopAddress(val) {
      const params = {
        addressId: val.id,
        isDefault: Number(!val.isDefault),
      };
      const res = await setDefaultStuShopAddress(params);
      if (res.code == 0) {
        this.getUserAddress();
      }
    },
    // 编辑收货地址
    editAddress() {
      if (this.UserAddress.length > 0) {
        this.editaddressDialog = true;
      } else {
        this.addressDialog = true;
      }
    },
    addAddress() {
      this.addressForm = {};
      this.addressDialog = true;
    },
    cancelClick() {
      this.UserAddress.forEach((item) => {
        if (item.id === this.addressForm.id) {
          item.address = this.add;
        }
      });
      this.addressForm = {};
      this.add = "";
      this.addressDialog = false;
    },
  },
};
</script>
<style lang="less" scoped>
.orderView {
  width: 100%;
  .confirm_order {
    width: 1200px;
    margin: 0 auto;
    .order_text {
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: bold;
      color: #333333;
      margin: 20px 0 10px 0;
    }
    .order_centent {
      width: 100%;
      height: 55px;
      background-color: #fff;
      border-radius: 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      color: #444;
      font-size: 14px;
      .centent_left {
        .order_pay {
          font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
          font-weight: bold;
          color: #444444;
        }
      }

      .centent_right {
        display: flex;
        align-items: center;
        .right_time {
          font-family: MicrosoftYaHei-, MicrosoftYaHei;
          font-weight: normal;
          width: auto;
          margin: 0 5px;
          /deep/ .number {
            font-size: 14px !important;
          }
        }
        .right_time1 {
          /deep/ .number {
            color: #ff5d51;
          }
        }
        .right_time2 {
          /deep/ .number {
            color: #00ab8c;
          }
        }
        .right_time3 {
          /deep/ .number {
            color: #006de7;
          }
        }
        .right_time4 {
          /deep/ .number {
            color: #1061ff;
          }
        }
      }
    }
  }
  .shipping_address {
    width: 1200px;
    margin: 0 auto;
    .address_text {
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: bold;
      color: #333333;
      margin: 20px 0 10px 0;
    }
    .address_centent {
      width: 1200px;
      background-color: #fff;
      border-radius: 7px;
      position: relative;
      .centent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        cursor: pointer;
        .centent_left {
          display: flex;
          align-items: center;
          .left_img {
            width: 69px;
            height: 69px;
            margin-right: 20px;
          }
          .left_text {
            cursor: pointer;
            font-size: 18px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: normal;
            color: #333333;
            .consignee {
              margin-bottom: 12px;
              .consignee_phone {
                margin-left: 10px;
              }
            }
            .consignee_address {
              width: 1000px;
            }
            // .shou {
            //   font-size: 17px;
            //   font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
            //   font-weight: 500;
            //   color: #333333;
            // }
          }
        }
      }
      .centent_bg {
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0;
        bottom: 10px;
        /deep/ .el-image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .commodity {
    width: 1200px;
    margin: 0 auto;
    .commodity_text {
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: bold;
      color: #333333;
      margin: 20px 0 10px 0;
    }
    .commodity_centent {
      width: 1200px;
      background: #ffffff;
      border-radius: 7px 7px 7px 7px;

      .item {
        padding: 0 30px 0px 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .centent_left {
          width: 114px;
          height: 114px;
          background: #f5f7f9;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px 5px 5px 5px;
          .img {
            width: 79px;
            height: 104px;
          }
        }
        .centent_right:nth-last-child(1) {
          border: none;
        }
        .centent_right {
          width: 1025px;
          height: 150px;
          margin-left: 20px;
          border-bottom: 1px solid #eeeeee;
          display: flex;
          justify-content: space-between;
          .right_name {
            margin-top: 30px;
            .name {
              width: 850px;
              font-size: 18px;
              font-family: MicrosoftYaHei-, MicrosoftYaHei;
              font-weight: normal;
              color: #333333;
            }
            .weight {
              font-size: 14px;
              font-family: MicrosoftYaHei-, MicrosoftYaHei;
              font-weight: normal;
              // color: #ff5d51;
              margin-top: 30px;
            }
          }
          .right_price {
            margin-top: 45px;
            text-align: right;
            .price {
              font-size: 20px;
              font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
              font-weight: bold;
              color: #333333;
            }
            .num {
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              margin-top: 10px;
            }
          }
        }
        // .line{
        //   width: 1200px;
        //   height: 2px;
        //   background-color: #eee;
        // }
      }
      .item:nth-last-child(1) {
        .centent_right {
          border: none;
        }
        //   .line{
        //     width: 1200px;
        //     height: 0px;
        //   }
      }
      // border-bottom: 1px solid #eee;
    }
  }
  .supporting {
    width: 1200px;
    margin: 0 auto;
    .supporting_text {
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: bold;
      color: #333333;
      margin: 20px 0 10px 0;
    }
    .supporting_centent {
      width: 100%;
      height: 55px;
      background-color: #fff;
      border-radius: 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      color: #444;
      font-size: 14px;
      .freight {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        .freight_pri {
          font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
          font-weight: bold;
          color: #333333;
          margin-left: 5px;
        }
      }
    }
  }
  .pay {
    width: 1200px;
    margin: 0 auto;
    .pay_text {
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: bold;
      color: #333333;
      margin: 20px 0 10px 0;
    }
    .pay_centent {
      width: 1200px;
      height: 99px;
      background-color: #fff;
      border-radius: 7px;
      display: flex;
      align-items: center;
      .centent_left {
        padding: 0 20px;
        .left_radio {
          width: 176px;
          height: 56px;
          padding: 0 !important;
          /deep/ .el-radio__inner {
            display: none;
          }
          /deep/ .el-radio__label {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            padding-top: 2px;
          }
          .left_img {
            width: 22px;
            height: 22px;
            margin-right: 10px;
          }
          .left_payment {
            font-size: 18px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            color: #333333;
          }
        }
      }
      .left1,
      .left4 {
        /deep/ .is-checked {
          background: rgba(255, 93, 81, 0.1);
          border-color: #ff5d51 !important;
        }
      }
      .left2 {
        /deep/ .is-checked {
          background: rgba(0, 171, 140, 0.1);
          border-color: #00ab8c !important;
        }
      }
      .left3 {
        /deep/ .is-checked {
          background: rgba(37, 134, 245, 0.1);
          border-color: #2586f5 !important;
        }
      }
    }
  }
  .payment {
    width: 1200px;
    height: 90px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 7px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    .pay_money {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #777777;
      .money {
        font-size: 30px;
        font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
        font-weight: 600;
        color: #f9431e;
        margin-left: 12px;
      }
    }
    .payment_btn {
      width: 220px;
      height: 48px;
      background: linear-gradient(88deg, #ff6d1e 0%, #fe420e 100%);
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
      margin-left: 20px;
      /deep/ .el-button {
        width: 220px;
        height: 48px;
        background-color: transparent;
        border-radius: 24px 24px 24px 24px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        border: none;
      }
    }
  }
  .pay-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .iconfont {
      font-size: 80px;
    }
    .icon-a-zu16 {
      color: #5ab4fc;
    }
    .icon-a-zu17 {
      color: #fa6450;
    }
    .tishi-title {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 30px;
      color: #333333;

      margin-top: 40px;
    }
    .tishi-tips {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 30px;
      color: #666666;

      margin-top: 24px;
    }
    /deep/ .el-button {
      width: 225px;
      margin-top: 56px;
      height: 46px;
      background: #ff4027;
    }
  }
  .editaddressDialog {
    /deep/ .el-dialog {
      width: 926px;
      height: 520px;
      overflow-y: auto;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
      margin-top: 25vh !important;
      .address {
        padding: 10px 0 33px 0;
        .addressAdd {
          width: 110px;

          .addBtn {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
          }
          .addBtn1 {
            color: #ff5d51;
            border-color: #ff5d51;
            height: 36px;
            line-height: 11px;
          }
          .addBtn1:hover {
            background-color: rgba(255, 93, 81, 0.1);
          }
          .addBtn2 {
            color: rgba(0, 171, 140, 1);
            border-color: rgba(0, 171, 140, 1);
            height: 36px;
            line-height: 11px;
          }
          .addBtn2:hover {
            background: rgba(0, 171, 140, 0.1);
          }
          .addBtn3:hover {
            background-color: rgba(16, 97, 255, 0.1);
          }
          .addBtn4:hover {
            background-color: rgba(16, 97, 255, 0.1);
          }
          .addBtn3 {
            color: #006de7;
            border-color: #006de7;
            height: 36px;
            line-height: 11px;
          }
          .addBtn4 {
            color: #1061ff;
            border-color: #1061ff;
            height: 36px;
            line-height: 11px;
          }
        }
        .addressBox {
          width: 100%;
          background-color: #ffffff;
          border-radius: 7px 7px 7px 7px;
          display: flex;
          align-items: start;
          justify-content: flex-start;
          margin: 26px auto 0;
          padding-top: 19px;
          padding-bottom: 20px;
          padding-left: 20px;
          background-image: url("~@/assets/img/textmall/line2.png");
          background-repeat: no-repeat;
          background-size: 900px 4px;
          background-position: bottom;
          box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06);
          opacity: 1;
          position: relative;
          .default_img {
            width: 34px;
            height: 19px;
            position: absolute;
            top: 0;
            left: -2px;
          }
          .address_centent {
            width: 100%;
            .address_name {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #666666;
              .name {
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                margin-right: 100px;
              }
            }
            .centent_add {
              display: flex;
              align-items: flex-end;
              margin-top: 12px;
              position: relative;
              .add_text {
                width: 516px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                .add {
                  font-size: 16px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #333333;
                }
              }
              .centent_choose {
                position: absolute;
                right: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                .default {
                  display: flex;
                  align-items: center;
                  margin-left: 30px;
                  cursor: pointer;
                  .add_img {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                  }
                  .circle {
                    width: 20px;
                    height: 20px;
                    background: #ffffff;
                    opacity: 1;
                    border-radius: 50%;
                    border: 1px solid #999999;
                    margin-right: 5px;
                  }
                  // .add_def_text {
                  //   font-size: 16px;
                  //   font-family: PingFang SC-Regular, PingFang SC;
                  //   font-weight: 400;
                  //   color: #ff5d51;
                  // }
                  .deit_img {
                    width: 14px;
                    height: 15px;
                    margin-right: 5px;
                  }
                  .deit_text {
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                  }
                  .deit_text1 {
                    color: #ff5d51;
                  }
                  .deit_text2 {
                    color: #00ab8c;
                  }
                  .deit_text4 {
                    color: #1061ff;
                  }
                  .deit_text3 {
                    color: #006de7;
                  }
                  .del_img {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                  }
                  .del_text {
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #d81e06;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .editaddressDialog {
    /deep/ .el-dialog__close {
      font-size: 22px;
    }
    /deep/ .el-dialog__title {
      font-weight: 600;
    }
  }
  .addressDialog {
    /deep/ .el-dialog {
      width: 580px !important;
      border-radius: 12px;
      margin-top: 30vh !important;
    }
    /deep/ .el-dialog__title {
      font-weight: 600;
    }
    /deep/ .el-dialog__close {
      font-size: 22px;
    }
    /deep/ .el-dialog__header {
      padding-bottom: 33px;
    }
  }
}
.orderView4 {
  padding-bottom: 250px;
}
.tip1 {
  color: #ff5d51;
}
.tip2 {
  color: #00ab8c;
}
.tip3 {
  color: #2586f5;
}
.tip4 {
  color: #1061ff;
}
</style>
